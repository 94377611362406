<script lang="ts" setup>
	import type { IUser } from "~/server/models/user";
	import { ClientReloadPolicy } from "~/services/store";
	import type { Sizes } from "~/utils/enums";
	import { useUser } from "~~/stores/user";

	const props = defineProps<{
		id?: string;
		record?: IUser;
		size: Sizes;
	}>();

	const user = useUser();

	//

	const color = computed(() => {
		return user.id === props.id ? "black" : "yellow";
	});

	const width = computed(() => {
		if (props.size === "small") {
			return 42;
		} else if (props.size === "medium") {
			return 56;
		} else {
			return 96;
		}
	});

	defineEmits<{
		(e: "click"): void;
	}>();
</script>

<template>
	<div @click="$emit('click')" class="profile-picture" :class="[size, color]">
		<RecordUser v-if="id" :reload-on-client="ClientReloadPolicy.never" :id="id" v-slot="{ record: loadedRecord }">
			<template v-if="loadedRecord">
				<cloudflare-image v-if="loadedRecord?.profile_picture?.id ? true : false" :value="loadedRecord?.profile_picture" :width="width" />
				<span v-else>{{ loadedRecord.first_name.charAt(0) }}{{ loadedRecord.last_name.charAt(0) }}</span>
			</template>
		</RecordUser>
		<template v-if="record">
			<cloudflare-image v-if="record?.profile_picture?.id ? true : false" :value="record?.profile_picture" :width="width" />
			<span v-else>{{ record.first_name.charAt(0) }}{{ record.last_name.charAt(0) }}</span>
		</template>
	</div>
</template>

<style scoped lang="scss">
	.profile-picture {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		user-select: none;
		.cloudflare-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		span {
			font-weight: 500;
		}

		&.black {
			background: var(--black);
			span {
				color: var(--white);
			}
		}

		&.yellow {
			background: var(--yellow);
			span {
				color: var(--black);
			}
		}

		&.tiny {
			height: 42px;
			width: 42px;
			border-radius: 6px;
			span {
				font-size: 18px;
				letter-spacing: -0.4px;
			}
		}

		&.small {
			height: 42px;
			width: 42px;
			border-radius: 6px;
			span {
				font-size: 18px;
				letter-spacing: -0.3px;
			}
		}

		&.medium {
			height: 56px;
			width: 56px;
			border-radius: 14px;
			span {
				font-size: 22px;
				letter-spacing: -0.8px;
			}
		}

		&.large {
			height: 96px;
			width: 96px;
			border-radius: 20px;
			span {
				font-size: 36px;
				letter-spacing: -1px;
			}
		}
	}
</style>
